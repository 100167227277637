import React from "react";
import { useParams } from "react-router-dom";
import { StringParam, useQueryParam } from "use-query-params";

import {
  LoggedOutAuth,
  LoggedInAuth,
  SignInProviderInfo,
} from "../hooks/useAuth";
import { makeApiRequest, ApiResponse } from "../lib/api/api";

import CreateAccount from "./CreateAccount";
import {
  getAuth,
  getRedirectResult,
  getAdditionalUserInfo,
} from "firebase/auth";
import Loading from "./Loading";
import { getUrlOrigin } from "../lib/utils";
import { ApiOrganizationAuthenticationConfig } from "../lib/api/auth";
import * as C from "../lib/constants";

/**
 * New signup handler that uses the signup token–based approach to authorizing a
 * user joining an organization.
 *
 * Loads org information from the token endpoint, and does an API POST to create
 * the user record in Firebase.
 */

export type ApiSignupToken = {
  token: string;
  role: "readonly" | "regular" | "owner";
  createdAt: string;
  isExpired: string;
  organizationId: string;
  organizationName: string;
  organizationAuthenticationConfig?: ApiOrganizationAuthenticationConfig;
  organizationTiers: {
    lens: C.LENS_TIERS_TYPE | null;
    hydroforecast: null;
  };
  products: C.ORG_PRODUCTS_TYPE[];
  numUsers: number;
};

const TokenSignUp: React.FunctionComponent<{
  auth: LoggedOutAuth | LoggedInAuth;
}> = ({ auth }) => {
  const { token } = useParams();

  const [tokenInfo, setTokenInfo] = React.useState<ApiSignupToken | null>(null);
  const [authRedirectLoading, setAuthRedirectLoading] =
    React.useState<boolean>(true);
  const [errorToRender, setErrorToRender] = React.useState<string | null>(null);
  const nameQueryParam = useQueryParam("name", StringParam)[0];

  const makeAcceptSignupRequest = React.useCallback(
    async (name: string) => {
      await makeApiRequest<void>(`signup/${token}/accept`, null, {
        method: "POST",
        body: JSON.stringify({ name }),
      });
      // Force Firebase to refresh the JWT token to bust through the caches
      // in the API. Otherwise if the user switched orgs via a signup token
      // we'll have the old org association cached.
      const auth = getAuth();
      await auth.currentUser?.getIdToken(true);
      window.location.href = `${getUrlOrigin(window.location.href)}`;
    },
    [token],
  );

  React.useEffect(() => {
    const fetchOrgByToken = async () => {
      const tokenResult: ApiResponse<ApiSignupToken, false> =
        await makeApiRequest(`signup/${token}`);

      if (tokenResult.error && tokenResult.error === "Not found") {
        setAuthRedirectLoading(false);
        setErrorToRender(
          "Invalid signup link: it is either incorrect or expired. Please ask your administrator for a new one.",
        );
        return;
      } else if (tokenResult.error) {
        setAuthRedirectLoading(false);
        setErrorToRender(
          `There was an error loading the invitation. Please contact us at team@upstream.tech`,
        );
        console.error(tokenResult.error);
        return;
      }

      const tokenData = tokenResult.data;
      const { organizationId, products, numUsers, organizationTiers } =
        tokenData;

      // HF does not have seat count maximums, so we only validate for lens
      // This does mean that any org with a dual subscription will not have
      // lens seat count enforced
      if (
        !products.includes(C.ORG_PRODUCT_HYDROFORECAST) &&
        products.includes(C.ORG_PRODUCT_LENS)
      ) {
        const organizationTier = organizationTiers.lens || C.LENS_TIER_FOCUS;
        const seatLimit = C.LENS_TIER_SETTINGS[organizationTier].seatLimit;
        if (
          !C.ORG_IDS_WITHOUT_SEAT_LIMIT.includes(
            organizationId as C.ORG_IDS_WITHOUT_SEAT_LIMIT_TYPE,
          ) &&
          seatLimit &&
          numUsers >= seatLimit
        ) {
          setAuthRedirectLoading(false);
          setErrorToRender(
            `This organization has reached its seat limit of ${seatLimit} seats. Please contact your administrator or reach out to us at team@upstream.tech.`,
          );
          return;
        }
      }

      setTokenInfo(tokenData);
    };
    fetchOrgByToken();
  }, [token]);

  React.useEffect(() => {
    const checkRedirect = async () => {
      if (tokenInfo) {
        const firebaseAuth = getAuth();
        const result = await getRedirectResult(firebaseAuth);
        if (result && auth.status === "logged in") {
          const additionalUserInfo = getAdditionalUserInfo(result);
          // If isNewUser is false then a user with that email already exists
          // and we don't want to add them to this organization
          if (!additionalUserInfo?.isNewUser) {
            auth.signOut();
            alert("A user already exists with that email address.");
          } else if (
            result.operationType === "signIn" &&
            result.providerId ===
              tokenInfo.organizationAuthenticationConfig?.provider_id
          ) {
            await makeAcceptSignupRequest(nameQueryParam as unknown as string);
          }
        } else {
          setAuthRedirectLoading(false);
        }
      }
    };
    checkRedirect();
  }, [auth, tokenInfo, makeAcceptSignupRequest, nameQueryParam]);

  const signUp = React.useCallback(
    async (name: string, providerInfo: SignInProviderInfo) => {
      if (auth.status === "logged out") {
        if (providerInfo.kind === "password") {
          await auth.makeUser(providerInfo.email, providerInfo.password);
        } else {
          // Signing in an SSO user who does .....
          await auth.signIn(providerInfo);
        }
        // Note: Our auth object is still "logged out" at this point, but
        // internally Firebase has logged the new user in and AuthProvider may
        // even be listening for the new profile by now.
      }

      makeAcceptSignupRequest(name);
    },
    [auth, makeAcceptSignupRequest],
  );

  return errorToRender ? (
    <h1 className="text-red-500 bg-gray-100 border border-red-400 p-4 rounded-md shadow my-4 mx-auto text-center max-w-xl">
      {errorToRender}
    </h1>
  ) : authRedirectLoading ? (
    <Loading />
  ) : tokenInfo?.organizationName ? (
    <CreateAccount
      auth={auth}
      organizationId={tokenInfo.organizationId}
      organizationName={tokenInfo.organizationName}
      organizationAuthenticationConfig={
        tokenInfo.organizationAuthenticationConfig
      }
      signUp={signUp}
    />
  ) : null;
};

export default TokenSignUp;
