import {
  LENS_APP_PROD_URL,
  LENS_APP_DEV_URL,
  LENS_APP_STAGING_URL_PREFIX,
  HYDROFORECAST_APP_PROD_URL,
  HYDROFORECAST_APP_DEV_URL,
  HYDROFORECAST_APP_STAGING_URL_PREFIX,
} from "./constants";

// Map error types to user friendly status text
export const ERROR_TYPES: { [key: string]: string } = {
  "auth/user-not-found": "User not found.",
  "auth/wrong-password":
    "No user was found for that email/password combination.",
  unknown:
    "An unexpected error occurred. Please try again or contact Upstream support.",
};

// App Keys
export const LENS: string = "LENS";
export const HYDROFORECAST: string = "HYDROFORECAST";

// TODO (marsh): I think some of the following redirect logic, especially the
// starts/ends matching opens us to redirect attacks

// Maps app keys to known URLs
export const SUPPORTED_APPS_URLS: { [app: string]: Array<string> } = {
  [LENS]: [LENS_APP_DEV_URL, LENS_APP_PROD_URL],
  [HYDROFORECAST]: [HYDROFORECAST_APP_DEV_URL, HYDROFORECAST_APP_PROD_URL],
};

export const LENS_BASE_URL: string = process?.env?.REACT_APP_USE_LOCAL_LENS
  ? LENS_APP_DEV_URL
  : LENS_APP_PROD_URL;

export const HYDROFORECAST_BASE_URL: string = process?.env
  ?.REACT_APP_USE_LOCAL_HYDROFORECAST
  ? HYDROFORECAST_APP_DEV_URL
  : HYDROFORECAST_APP_PROD_URL;

export function isLensUrl(url: string) {
  return (
    url.startsWith(LENS_BASE_URL) ||
    (url.startsWith(LENS_APP_STAGING_URL_PREFIX) && url.endsWith(".web.app"))
  );
}

export function isHydroForecastUrl(url: string) {
  return (
    url.startsWith(HYDROFORECAST_BASE_URL) ||
    (url.startsWith(HYDROFORECAST_APP_STAGING_URL_PREFIX) &&
      url.endsWith(".web.app"))
  );
}

// Given SUPPORTED_APPS_URLS, return the app key to which the provided URL belongs, or undefined if none match.
export const getURLKind = (redirect: string | null): string | undefined => {
  if (!redirect) {
    return;
  }
  try {
    const { origin } = new URL(redirect);
    return Object.keys(SUPPORTED_APPS_URLS).find((APP) =>
      SUPPORTED_APPS_URLS[APP].includes(origin),
    );
  } catch (_e) {
    return;
  }
};

// Returns the referrer, returning null if none is set or if it matches the current location.href.
export const getReferrer = (): string | null => {
  const currentUrl = window.location.href;
  const referrer: string = document.referrer;
  return !referrer || referrer === currentUrl ? null : referrer;
};

export function getUrlOrigin(urlString: string): string | undefined {
  try {
    const url = new URL(urlString);
    return url.origin;
  } catch (_e) {
    return;
  }
}

export const isSSOProviderId = (providerId: string) =>
  providerId.startsWith("saml") ||
  providerId.startsWith("oauth") ||
  providerId === "microsoft.com";
