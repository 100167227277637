import React, { FormEvent } from "react";
import { Link, Navigate } from "react-router-dom";
import validator from "validator";
import {
  LoggedOutAuth,
  LoggedInAuth,
  SignInProviderInfo,
} from "../hooks/useAuth";
import { ApiResponseError } from "../lib/api/api";
import { getReferrer } from "../lib/utils";
import BrandingHeader from "../lib/components/Branding";

import * as tw from "../lib/tailwindClasses";
import { StringParam, useQueryParam } from "use-query-params";
import { ApiSignupToken } from "./TokenSignup";

interface Props {
  auth: LoggedOutAuth | LoggedInAuth;

  organizationId: string | null;
  organizationName: string | null;
  organizationAuthenticationConfig?: ApiSignupToken["organizationAuthenticationConfig"];
  signUp: (name: string, providerInfo: SignInProviderInfo) => Promise<void>;
}

interface FormState {
  nameInput: string;
  emailInput: string;
  passwordInput: string;
  confirmPasswordInput: string;
}

const MIN_PASSWORD_LENGTH = 6;

/**
 * View for creating an account and joining an organization from an invitation
 * link. Can be used by both the legacy signup links and the new signup token
 * links.
 */
const CreateAccount: React.FunctionComponent<Props> = ({
  auth,
  organizationId,
  organizationName,
  organizationAuthenticationConfig,
  signUp,
}) => {
  const [formState, setFormState] = React.useReducer(
    (state: FormState, action: Partial<FormState>) => {
      return { ...state, ...action };
    },
    {
      nameInput: "",
      emailInput: "",
      passwordInput: "",
      confirmPasswordInput: "",
    },
  );

  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [authError, setAuthError] = React.useState<string | null>(null);
  const [enterPasswordError, setEnterPasswordError] = React.useState<
    string | null
  >(null);
  const [confirmPasswordError, setConfirmPasswordError] = React.useState<
    string | null
  >(null);
  // Store the name in a query parameter so we can access it in TokenSignup when we redirect
  // back after signing in with SSO
  const [name, setName] = useQueryParam("name", StringParam);
  const referrer: string | null = getReferrer();
  const { emailInput, passwordInput, confirmPasswordInput } = formState;

  const onSubmit = React.useCallback(
    async (
      e: React.FormEvent<HTMLFormElement>,
      providerKind: "password" | "sso",
    ) => {
      e.preventDefault();

      if (isSubmitting || !name) {
        return;
      }

      setIsSubmitting(true);
      setAuthError(null);

      try {
        let providerInfo: SignInProviderInfo;

        if (
          providerKind === "sso" &&
          organizationAuthenticationConfig?.provider_id
        ) {
          providerInfo = {
            kind: "sso" as const,
            providerId: organizationAuthenticationConfig.provider_id,
          };
          if (organizationAuthenticationConfig.tenant) {
            providerInfo.tenant = organizationAuthenticationConfig.tenant;
          }
        } else {
          providerInfo = {
            kind: "password" as const,
            email: emailInput,
            password: passwordInput,
          };
        }
        await signUp(name, providerInfo);
        setIsSubmitting(false);
      } catch (e) {
        const error = e as ApiResponseError;
        setIsSubmitting(false);
        setAuthError(error.message || error.error.message);
      }
    },
    [
      name,
      isSubmitting,
      signUp,
      emailInput,
      passwordInput,
      organizationAuthenticationConfig,
    ],
  );

  const showSSO = organizationAuthenticationConfig?.provider_id;
  const showEmailPassword = !organizationAuthenticationConfig?.sso_required;
  const onlyShowSSO = showSSO && !showEmailPassword;

  // For handling cases when the user has a Firebase account but is not in any
  // orgs, or is in a different org.
  const loggedIn = auth.status === "logged in" && !isSubmitting;

  const createButtonText = React.useMemo(() => {
    // TODO: If we do a sign in with provider, are we logged in?
    if (loggedIn) {
      return "Join";
    }

    if (isSubmitting) {
      return "Submitting...";
    } else {
      if (onlyShowSSO) {
        return "Sign up with SSO";
      }
      return "Sign up with email and password";
    }
  }, [loggedIn, isSubmitting, onlyShowSSO]);

  const enableSubmitButtonEmailPassword =
    validator.isEmail(emailInput || "") &&
    !!passwordInput &&
    passwordInput.length >= MIN_PASSWORD_LENGTH;

  const enableSubmitButton =
    !!name &&
    (loggedIn || enableSubmitButtonEmailPassword || onlyShowSSO) &&
    !confirmPasswordError &&
    !isSubmitting;

  // If the user is already logged in with their current org, just redirect them
  // to the login page. Sometimes people save the invitation link as the way
  // they know how to get to Upstream.
  if (
    auth.status === "logged in" &&
    auth.profileOrganization &&
    auth.profileOrganization.id === organizationId
  ) {
    return <Navigate to={"/"} />;
  }

  return (
    <div className={tw.contentWrapper}>
      <div className={tw.section}>
        <BrandingHeader productUrl={referrer} />
        <h2 className={`${tw.sectionTitle} text-center`}>
          Join {organizationName}
        </h2>
        <form
          className="space-y-6"
          onSubmit={(e) => onSubmit(e, onlyShowSSO ? "sso" : "password")}
        >
          <div className="space-y-2">
            <label htmlFor="name" className={tw.formInputLabel}>
              Name
            </label>
            <div>
              <input
                id="name"
                type="text"
                placeholder="Jane Doe"
                value={name || ""}
                onChange={(event: FormEvent<HTMLInputElement>) => {
                  const element = event.currentTarget as HTMLInputElement;
                  const { value } = element;
                  setName(value, "replace"); // Use replace so we don't push to the history stack with every letter
                }}
                className={tw.formInput}
              />
            </div>
          </div>
          {showEmailPassword && (
            <>
              <div className="space-y-2">
                <label htmlFor="email" className={tw.formInputLabel}>
                  Email
                </label>
                <div>
                  <input
                    id="email"
                    name="email"
                    type="email"
                    placeholder="jane.doe@conservation.inc"
                    value={
                      auth.status === "logged in"
                        ? auth.firebaseEmail
                        : emailInput
                    }
                    onChange={(event: FormEvent<HTMLInputElement>) => {
                      const element = event.currentTarget as HTMLInputElement;
                      const { value } = element;
                      setFormState({ emailInput: value });
                    }}
                    readOnly={loggedIn}
                    className={tw.formInput}
                  />
                </div>
              </div>
              {!loggedIn && (
                <>
                  <div className="space-y-2">
                    <label htmlFor="password" className={tw.formInputLabel}>
                      Password
                    </label>
                    <div>
                      <input
                        id="password"
                        name="password"
                        type="password"
                        placeholder="**********"
                        value={passwordInput}
                        onChange={(event: FormEvent<HTMLInputElement>) => {
                          const element =
                            event.currentTarget as HTMLInputElement;
                          const { value } = element;
                          setFormState({ passwordInput: value });
                        }}
                        onBlur={() => {
                          if (passwordInput.length < MIN_PASSWORD_LENGTH) {
                            setEnterPasswordError(
                              `Password must be at least ${MIN_PASSWORD_LENGTH} characters long`,
                            );
                          }
                        }}
                        onFocus={() => {
                          setEnterPasswordError(null);
                          setConfirmPasswordError(null);
                        }}
                        className={tw.formInput}
                      />
                      {enterPasswordError && (
                        <div className={tw.errorText}>{enterPasswordError}</div>
                      )}
                    </div>
                  </div>
                  <div className="space-y-2">
                    <label
                      htmlFor="confirmPassword"
                      className={tw.formInputLabel}
                    >
                      Confirm Password
                    </label>
                    <div>
                      <input
                        id="confirmPassword"
                        type="password"
                        placeholder="**********"
                        value={confirmPasswordInput}
                        onChange={(event: FormEvent<HTMLInputElement>) => {
                          const element =
                            event.currentTarget as HTMLInputElement;
                          const { value } = element;
                          setFormState({
                            confirmPasswordInput: value,
                          });
                        }}
                        onBlur={() => {
                          if (
                            passwordInput.length > 0 &&
                            confirmPasswordInput !== passwordInput
                          ) {
                            setConfirmPasswordError("Passwords must match");
                          }
                        }}
                        onFocus={() => {
                          setConfirmPasswordError(null);
                        }}
                        className={tw.formInput}
                      />
                      {confirmPasswordError && (
                        <div className={tw.errorText}>
                          {confirmPasswordError}
                        </div>
                      )}
                      {authError && (
                        <div className={tw.errorText}>{authError}</div>
                      )}
                    </div>
                  </div>
                </>
              )}
            </>
          )}
          <p className="text-sm">
            By signing up, you agree to the Upstream Tech Lens{" "}
            <a
              href="https://upstream.tech/licenses"
              target="_blank"
              rel="noopener noreferrer"
              className={tw.link}
            >
              End User License Agreement
            </a>
            .
          </p>

          {auth.status === "logged in" &&
            auth.profileOrganization &&
            auth.profileOrganization.id !== organizationId && (
              <div>
                You’re currently logged in as a member of{" "}
                {auth.profileOrganization?.name ? (
                  <strong>{auth.profileOrganization.name}</strong>
                ) : (
                  "a different organization"
                )}
                . Submitting this form will switch your organization to{" "}
                <strong>{organizationName}</strong>. If you don’t want to do
                that, you can{" "}
                <Link className={tw.link} to="/signout">
                  sign out
                </Link>{" "}
                or{" "}
                <Link className={tw.link} to="/projects">
                  go to your current organization’s projects
                </Link>
                .
              </div>
            )}

          <button
            disabled={!enableSubmitButton}
            type="submit"
            className={tw.buttonPrimary}
          >
            {createButtonText}
          </button>
        </form>
        {/*
            Put this SSO button in its own form so if you use the tab key to navigate
            down to it you can then press enter to trigger the onSubmit
        */}
        {!loggedIn && showSSO && !onlyShowSSO && (
          <form onSubmit={(e) => onSubmit(e, "sso")} className="mt-5 space-y-6">
            <div className="relative">
              <div
                className="absolute inset-0 flex items-center"
                aria-hidden="true"
              >
                <div className="w-full border-t-2 border-gray-300" />
              </div>
            </div>
            <button
              disabled={!name || isSubmitting}
              type="submit"
              className={tw.buttonPrimary}
            >
              Sign up with SSO
            </button>
          </form>
        )}

        {!loggedIn && (
          <div className="text-center text-sm mt-6">
            Already have an account?{" "}
            <Link className={tw.link} to={`/`}>
              Sign in.
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default CreateAccount;
