// Use singletons since this is only analyzed when the bundle is loaded, so no
// harm, which also allow us to export a literal instead of needing a func.
//
// We’re not converting this to TypeScript since it doesn’t allow the
// module.exports updating that we’re relying on here.
export let isTesting, isDev, isProd, isStaging;

getEnvValues();

function getEnvValues() {
  isProd = process?.env.NODE_ENV === "production";
  // NOTE: isStaging is separate from NODE_ENV. NODE_ENV == production on staging builds
  isStaging = !!process?.env.REACT_APP_IS_STAGING;
}
