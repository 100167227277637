import React from "react";
import { useNavigate } from "react-router-dom";
import { LoggedInAuth, LoggedOutAuth } from "../hooks/useAuth";

export type Props = {
  auth: LoggedInAuth | LoggedOutAuth;
};

export default function SignOut({ auth }: Props) {
  const navigate = useNavigate();

  // Signs the user out or redirects. We only want this to run once.
  React.useEffect(() => {
    if (auth.status === "logged in") {
      auth.signOut();
    } else {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (auth.status === "logged out") {
      navigate("/");
    }
  }, [auth.status, navigate]);

  return <div>Signing out…</div>;
}
