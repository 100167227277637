import React, { ReactNode } from "react";
import useAuth, {
  Auth,
  AuthContext,
  LoggedInAuth,
  LoggedOutAuth,
  makeLoggedInAuth,
  makeLoggedOutAuth,
} from "../hooks/useAuth";
import useFirebaseUser from "../hooks/useFirebaseUser";
import useUserProfileQuery from "../hooks/useUserProfile";
import useOrganizationQuery from "../hooks/useOrganization";

export const WithAuth: React.FunctionComponent<{
  children: (auth: Auth) => JSX.Element;
}> = ({ children }) => {
  const auth = useAuth();
  return children(auth);
};

/**
 * Renders its child function prop if auth is logged in, otherwise redirects to
 * redirectPath.
 */
export const WithLoggedInAuth: React.FunctionComponent<{
  children: (auth: LoggedInAuth) => JSX.Element;
}> = ({ children }) => {
  const auth = useAuth();
  if (auth.status === "logged in") {
    return children(auth);
  } else {
    return <div />;
  }
};

/**
 * Renders its child function prop if auth is logged out, otherwise redirects to
 * redirectPath.
 */
export const WithLoggedOutAuth: React.FunctionComponent<{
  children: (auth: LoggedOutAuth) => JSX.Element;
}> = ({ children }) => {
  const auth = useAuth();

  if (auth.status === "logged out") {
    return children(auth);
  } else {
    return <div />;
  }
};

export const AuthProvider: React.FunctionComponent<{
  children: ReactNode;
  renderLoading: () => JSX.Element;
}> = ({ children, renderLoading }) => {
  const [auth, setAuth] = React.useState<Auth | undefined>(undefined);
  const firebaseUser = useFirebaseUser();
  const { data: currentUser, isFetched: isCurrentUserFetched } =
    useUserProfileQuery(firebaseUser);
  const { data: profileOrganization, isFetched: isProfileOrganizationFetched } =
    useOrganizationQuery(currentUser);
  // This useEffect ensures a continuity of rendering pages. When the app is
  // first running and we don’t know if the user is logged in or not, auth is
  // undefined and we render a loading indicator. This loading indicator
  // continues while the profile and organization are loading (if the user is
  // actually logged in).
  //
  // If the user is logged out and logs in, the state says as a "logged out"
  // auth object until the profile and organization have completely loaded
  // (either with values or null responses). This keeps us from unmounting all
  // components, which would happen if we switched to a top level loading
  // indicator.
  React.useEffect(() => {
    if (firebaseUser === null) {
      setAuth(makeLoggedOutAuth());
    } else if (
      firebaseUser &&
      isCurrentUserFetched &&
      isProfileOrganizationFetched
    ) {
      setAuth(makeLoggedInAuth(firebaseUser, profileOrganization || null));

      // If we were logged in and then the situation changes, we clear auth. If
      // we’re still logged in (and this ran because the profile or organization
      // objects changed) then we’re going to immediately re-setAuth with
      // another logged in state. If we logged out (meaning firebaseUser is
      // null) we’ll immediately setAuth with a logged-out state.
      //
      // The other case is if we switch auth from one user to another without an
      // intermediate log out, in which case we don’t want to render logged in
      // until the profile and organization change, but we don’t want to render
      // logged out because that might cause redirects. Instead, we’ll render
      // the loading indicator (auth === undefined) until organization and
      // profile load, in which case we’ll setAuth back to the logged in state.
      return () => {
        setAuth(undefined);
      };
    }
  }, [
    firebaseUser,
    profileOrganization,
    isCurrentUserFetched,
    isProfileOrganizationFetched,
  ]);

  if (auth === undefined) {
    return renderLoading();
  } else {
    return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
  }
};
