import { getAuth } from "firebase/auth";
import * as env from "../env";

const USE_LOCAL_API = !!process?.env?.REACT_APP_USE_LOCAL_API && !env.isProd;

let API_ROOT = "https://api.upstream.tech";
if (USE_LOCAL_API) {
  API_ROOT = "http://localhost:8080";
}

type ApiParams = {
  [k: string]: string[] | string | number | number[] | boolean;
};

export type Paging<K> = {
  afterToken: K | null;
  beforeToken: K | null;
  pageIndex?: number;
  isFinishedPaging?: boolean;
};

export type ApiResponse<T, K> = {
  data: T;
  paging?: Paging<K>;
  error?: string;
};

/* Utility type for thrown errors. */
/* makeRequest throws a few different types of things, which this type is meant to superficially describe */
export type ApiResponseError = {
  error: Error;
  cause?: Error;
  body?: any;
  status?: Response["status"];
  message?: string;
  shouldRetry?: boolean;
};

const DEFEAULT_REQUEST_OPTIONS: RequestInit = {
  method: "GET",
  mode: "cors",
  cache: "default",
};

/**
 * contextOrganizationId is to keep track of the organization a user is viewing in Lens.
 * We add this as a header to all API calls to support parent organizations. User's who belong to a parent
 * organization are able to experience Lens as a member of any of their child orgs, but this means the
 * user profile and token are no longer sufficent for knowing what org a request is for, so we need to
 * specify the org. This also avoids the API having to infer the the organization from information like
 * the featureCollection in non parent org cases.
 *
 * setContextOrganizationId is called whenever the organizationId changes to keep the contextOrganizationId global up to date
 */
export let contextOrganizationId: string | null = null;
export const setContextOrganizationId = (id: string | null) => {
  contextOrganizationId = id;
};

export async function makeApiRequest<T, K = never>(
  route: string,
  params: null | ApiParams = null,
  options: RequestInit = {},
  { noContextOrg = false }: { noContextOrg?: boolean } = {}
): Promise<ApiResponse<T, K>> {
  const opts = {
    ...DEFEAULT_REQUEST_OPTIONS,
    ...options,
  };

  const url = getApiRoute(route, params);
  const headers = new Headers();
  const auth = getAuth();

  const pageUrl = new URL(window.location.href);
  const apiKey = pageUrl.searchParams.get("apiKey");
  if (apiKey) {
    headers.append("Authorization", apiKey);
  } else if (auth.currentUser) {
    const token = await auth.currentUser.getIdToken();
    if (token) {
      headers.append("Authorization", `jwt ${token}`);
    }
  }

  // POST requests with form data are assigned a Content-Type header based on
  // the type of encoding.
  const isFormPost = opts.method === "POST" && opts.body instanceof FormData;
  if (!isFormPost) {
    headers.append("Content-Type", "application/json");
  }

  if (contextOrganizationId && !noContextOrg) {
    headers.append("x-context-organization-id", contextOrganizationId);
  }

  const response = await fetch(url, {
    headers,
    ...opts,
  });
  const responseText = await response.text();
  return JSON.parse(responseText);
}

function getApiRoute(route: string, params: ApiParams | null = {}): string {
  if (route.startsWith(API_ROOT)) {
    return `${route}${serialize(params)}`;
  } else {
    return `${API_ROOT}/${route}${serialize(params)}`;
  }
}

function serialize(
  obj: null | {
    [key: string]: string[] | string | number[] | number | boolean;
  }
): string {
  if (!obj || !Object.keys(obj).length) {
    return "";
  }

  let str: string[] = [];
  for (const p in obj) {
    const val = obj[p];

    if (Array.isArray(val)) {
      str = str.concat(
        val.map((v) => `${encodeURIComponent(p)}=${encodeURIComponent(v)}`)
      );
    } else if (Object.prototype.hasOwnProperty.call(obj, p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(val));
    }
  }
  return "?" + str.join("&");
}
