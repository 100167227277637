import React from "react";
import { getApp } from "firebase/app";
import {
  getAuth,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  sendEmailVerification,
  User,
  createUserWithEmailAndPassword,
  SAMLAuthProvider,
  OAuthProvider,
  signInWithRedirect,
  linkWithRedirect,
  fetchSignInMethodsForEmail,
} from "firebase/auth";
import {
  ApiOrganization,
  ApiOrganizationAuthenticationConfig,
} from "../lib/api/auth";

// import { ApiOrganization, ApiUserProfile } from "../lib/api/auth";
// import { QueryClient } from "react-query";

export type LoggedInAuth = {
  status: "logged in";

  firebaseUid: string;
  firebaseEmail: string;
  // firebaseToken: string;
  firebaseIsEmailVerified: boolean;
  profileOrganization: ApiOrganization | null;
  photoUrl: string | null | undefined;
  /** Time in seconds since the user signed up */
  signupTimeSecs: number;
  signOut: () => Promise<void> | void;
  sendVerificationEmail: () => Promise<void> | void;

  linkSSO: () => Promise<void>;
  getSignInMethods: () => Promise<string[]>;
};

export type LoggedOutAuth = {
  status: "logged out";
  signIn: (provider: SignInProviderInfo) => Promise<void>;
  makeUser: (email: string, password: string) => Promise<unknown>;
  resetPassword: (email: string) => Promise<void>;
};

export function makeLoggedInAuth(
  firebaseUser: User,
  profileOrganization: ApiOrganization | null,
): LoggedInAuth {
  const app = getApp();
  const auth = getAuth(app);

  return {
    status: "logged in",
    firebaseUid: firebaseUser.uid,
    firebaseEmail: firebaseUser.email!,
    // firebaseToken: idToken,
    firebaseIsEmailVerified: !!firebaseUser.emailVerified,
    signupTimeSecs: firebaseUser.metadata.creationTime
      ? Date.parse(firebaseUser.metadata.creationTime) / 1000
      : 0,
    photoUrl: auth.currentUser?.photoURL,
    profileOrganization,

    signOut: async () => {
      await auth.signOut();
    },
    sendVerificationEmail: () => sendEmailVerification(firebaseUser),
    linkSSO: async () => {
      const authenticationConfig =
        profileOrganization?.settings.authentication_config;
      if (!authenticationConfig) {
        throw new Error("User's organization must have sso configured");
      }
      const providerInfo = makeSSOProviderInfo(authenticationConfig);
      await linkWithRedirect(firebaseUser, getSSOAuthProvider(providerInfo));
    },
    getSignInMethods: async () => {
      const methods = await fetchSignInMethodsForEmail(
        auth,
        firebaseUser.email!,
      );
      return methods;
    },
  };
}

export function makeLoggedOutAuth(): LoggedOutAuth {
  const app = getApp();
  const auth = getAuth(app);

  return {
    status: "logged out",
    // In the old code, signIn and resetPassword were behind an
    // exponential backoff retry that retried once in case of failure. Is
    // that still necessary?
    signIn: async (providerInfo: SignInProviderInfo) => {
      if (providerInfo.kind === "password") {
        await signInWithEmailAndPassword(
          auth,
          providerInfo.email,
          providerInfo.password,
        );
      } else {
        const app = getApp();
        const firebaseAuth = getAuth(app);
        const provider = getSSOAuthProvider(providerInfo);
        await signInWithRedirect(firebaseAuth, provider);
      }
    },
    makeUser: async (email: string, password: string) => {
      await makeUser(email, password);
    },
    resetPassword: async (email: string) => {
      await sendPasswordResetEmail(auth, email);
    },
  };
}

export type Auth = LoggedInAuth | LoggedOutAuth;
export const AuthContext = React.createContext<Auth | undefined>(undefined);

export default function useAuth() {
  const auth = React.useContext(AuthContext);

  // AuthProvider enforces a contract that it will never render its children
  // unless auth is defined, so this is a programmer error rather than an odd
  // state.
  if (auth === undefined) {
    throw new Error("Auth consumer used outside of AuthProvider");
  }

  return auth;
}

async function makeUser(email: string, password: string) {
  const app = getApp();
  const auth = getAuth(app);

  const firebaseUser = (
    await createUserWithEmailAndPassword(auth, email, password)
  ).user;

  if (!firebaseUser) {
    throw new Error(
      "User creation was unsuccessful, but did not throw an error",
    );
  }

  return firebaseUser;
}

export type PasswordProviderInfo = {
  kind: "password";
  email: string;
  password: string;
};
export type SSOProviderInfo = {
  kind: "sso";
  providerId: string;
  tenant?: string;
};
// Discriminated union of sign in types
export type SignInProviderInfo = PasswordProviderInfo | SSOProviderInfo;

export function getSSOAuthProvider(providerInfo: SSOProviderInfo) {
  const providerId = providerInfo.providerId;
  if (providerId.startsWith("saml")) {
    return new SAMLAuthProvider(providerId);
  } else {
    const provider = new OAuthProvider(providerId);
    if (providerInfo.tenant) {
      provider.setCustomParameters({
        tenant: providerInfo.tenant,
      });
    }
    return provider;
  }
}

function makeSSOProviderInfo(
  authentication_config: ApiOrganizationAuthenticationConfig,
) {
  const providerInfo: SSOProviderInfo = {
    kind: "sso",
    providerId: authentication_config.provider_id,
  };
  if (authentication_config.tenant) {
    providerInfo.tenant = authentication_config.tenant;
  }

  return providerInfo;
}
