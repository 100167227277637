import { makeApiRequest } from "./api";

export type ApiOrganizationAuthenticationConfig = {
  provider_id: string;
  sso_required: boolean;
  tenant?: string;
};

export type ApiOrganizationSettings = {
  authentication_config?: ApiOrganizationAuthenticationConfig;
};

export type ApiOrganization = {
  id: string;
  name: string;
  parentOrganizationId: null;
  childOrganizations: ApiOrganization[];
  products: string[];
  settings: ApiOrganizationSettings;
};

export type ApiUserProfile = {
  id: string;
  name: string;
  email: string;
  organizationKey: string;
  role: "readonly" | "regular" | "owner";
};

export async function fetchOrganization(
  organizationId: ApiOrganization["id"],
): Promise<ApiOrganization> {
  const response = await makeApiRequest<ApiOrganization>(
    `organizations/${organizationId}`,
  );

  return response.data;
}

export async function fetchCurrentUser(): Promise<ApiUserProfile> {
  const response = await makeApiRequest<ApiUserProfile>("user");
  return response.data;
}
