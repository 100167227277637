import React from "react";
import isEmail from "validator/lib/isEmail";
import { useLocation } from "react-router-dom";

import { LoggedOutAuth } from "../hooks/useAuth";

import { ERROR_TYPES, getReferrer } from "../lib/utils";
import { InlineAppSwitch } from "./AppSwitch";
import BrandingHeader from "../lib/components/Branding";

import * as tw from "../lib/tailwindClasses";

type Props = {
  auth: LoggedOutAuth;
};

type Status = "unsubmitted" | "submitting" | "submitted";

export default function ForgotPassword({ auth }: Props) {
  const { state: locationState } = useLocation();
  const [email, setEmail] = React.useState<string>(locationState?.email || "");
  const [status, setStatus] = React.useState<Status>("unsubmitted");
  const [error, setError] = React.useState<null | string>(null);

  const isSubmitEnabled = isEmail(email) && status === "unsubmitted";

  const referrer: string | null = getReferrer();

  return (
    <div className={tw.contentWrapper}>
      <div className={tw.section}>
        <BrandingHeader productUrl={referrer} />
        <div className="text-center">
          <h1 className={tw.sectionTitle}>Reset your password</h1>
          {referrer ? (
            <p className="mt-2 text-sm text-gray-600">
              Or return to{" "}
              <a href={referrer} className={tw.link}>
                sign in
              </a>
              .
            </p>
          ) : (
            <>
              <div className="mt-2 text-sm text-gray-600">
                Or return to the app to sign in.
              </div>
              <InlineAppSwitch />
            </>
          )}
        </div>
        <form
          className="space-y-6"
          onSubmit={async (e: React.FormEvent) => {
            e.preventDefault();

            if (!isSubmitEnabled) {
              return;
            }

            setStatus("submitting");
            setError(null);

            try {
              await auth.resetPassword(email);
              setStatus("submitted");
            } catch (e: any) {
              const error = e.code;
              setError(error);
              setStatus("unsubmitted");
            }
          }}
        >
          <input type="hidden" name="remember" defaultValue="true" />
          <div className="space-y-2">
            <div>
              <label htmlFor="email" className={tw.formInputLabel}>
                Email address
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  type="email"
                  placeholder="name@company.com"
                  value={email}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setEmail(e.target.value.toLocaleLowerCase());
                  }}
                  tabIndex={1}
                  className={tw.formInput}
                />
              </div>
              {error && (
                <div className={tw.errorText}>
                  {ERROR_TYPES[error] || ERROR_TYPES["unknown"]}
                </div>
              )}
            </div>
          </div>
          <button
            className={tw.buttonPrimary}
            disabled={!isSubmitEnabled}
            type="submit"
            tabIndex={3}
          >
            {status === "unsubmitted" && "Send reset email"}
            {status === "submitting" && "Sending reset email…"}
            {status === "submitted" && "Reset email sent!"}
          </button>
        </form>
      </div>
    </div>
  );
}
