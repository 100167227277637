import React from "react";
import { fetchSignInMethodsForEmail, getAuth } from "firebase/auth";
import { Link, useNavigate } from "react-router-dom";
import { LoggedOutAuth } from "../hooks/useAuth";
import { ERROR_TYPES, isSSOProviderId } from "../lib/utils";
import BrandingHeader from "../lib/components/Branding";

import * as tw from "../lib/tailwindClasses";
import { LoadingSpinner } from "./Loading";

type Props = {
  auth: LoggedOutAuth;
};

export default function SignIn({ auth }: Props) {
  const [email, setEmail] = React.useState<string>("");
  const [providerId, setProviderId] = React.useState<string | null>(null);
  const [password, setPassword] = React.useState<string>("");
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<null | string>(null);

  const navigate = useNavigate();

  const isSubmitEnabled =
    email &&
    !isLoading &&
    (!providerId ||
      providerId !== "password" ||
      (providerId === "password" && password));
  const searchParams = new URLSearchParams(window.location.search);
  const redirectUrlParam = searchParams.get("redirect");

  const getProviderId = React.useCallback(async () => {
    setIsLoading(true);
    try {
      const _auth = getAuth();
      let methods = await fetchSignInMethodsForEmail(_auth, email);
      if (methods.length === 0) {
        setError("auth/user-not-found");
        console.error("No sign in methods found for email");
        return;
      }
      // If a user has sso and password auth configured, only let them sign in with sso.
      // This is only applicable in the case where a user signed up with password auth
      // and linked sso auth at a later date.
      // All other users will have one auth method.
      methods = methods.sort((a, _b) => (isSSOProviderId(a) ? -1 : 1));
      setProviderId(methods[0]);
    } catch (e) {
      const error = (e as { code: string }).code;
      console.error("Uknown error getting provider id", error);
      setError(error);
    } finally {
      setIsLoading(false);
    }
  }, [email]);

  const signIn = React.useCallback(async () => {
    // Sign in SSO or Email
    if (!providerId) {
      return;
    }

    setIsLoading(true);
    setError(null);

    let method;
    if (providerId === "password") {
      method = { kind: "password" as const, email, password };
    } else {
      method = { kind: "sso" as const, providerId };
    }

    try {
      await auth.signIn(method);

      // Navigate to appSelect page and persist any redirect url
      // that is present in the query params
      const urlQuery = window.location.search || "";
      navigate(`/appSwitch${urlQuery}`);
    } catch (e: any) {
      const error = e.code;
      if (error !== "auth/popup-closed-by-user") {
        console.error("Error signing in", error);
        setError(error);
      }
      // we don't set loading false in the non-error case because it
      // takes a moment after loading completes to redirect us from this
      // page
      setIsLoading(false);
    }
  }, [auth, email, password, providerId, navigate]);

  const renderEmailInput = () => (
    <div>
      <label htmlFor="email" className={tw.formInputLabel}>
        Email address
      </label>
      <div className="mt-2">
        {providerId ? (
          <div
            className={`${tw.formInput} bg-gray-200 flex justify-between items-center`}
          >
            <div>{email}</div>
            <button
              className="bg-gray-400 rounded-full text-slate-200 text-sm h-5 w-5"
              type="button" // So that the onClick isn't fired when pressing enter inside the <form/>
              onClick={() => {
                setProviderId(null);
                setEmail("");
                setPassword("");
                setError(null);
              }}
            >
              X
            </button>
          </div>
        ) : (
          <input
            id="email"
            name="email"
            type="email"
            autoComplete="email"
            autoFocus={true}
            placeholder="Enter your email"
            required
            className={`${tw.formInput} ${
              !!providerId && "disabled:opacity-50"
            }`}
            value={email}
            disabled={!!providerId}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (error) {
                setError(null);
              }
              setEmail(e.target.value.toLocaleLowerCase());
            }}
          />
        )}
      </div>
    </div>
  );

  const renderPasswordInput = () => (
    <div>
      <label htmlFor="password" className={tw.formInputLabel}>
        Password
      </label>

      <div className="mt-2">
        <input
          id="password"
          name="password"
          type="password"
          autoComplete="current-password"
          autoFocus={true}
          placeholder="Enter your password"
          required
          className={tw.formInput}
          value={password}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            if (error) {
              setError(null);
            }
            setPassword(e.target.value);
          }}
        />
      </div>
    </div>
  );

  const renderSubmitButton = () => {
    let submitButtonText;
    if (!providerId) {
      submitButtonText = "Continue";
    } else if (providerId === "password") {
      submitButtonText = "Sign in";
    } else {
      submitButtonText = "Sign in with SSO";
    }

    return (
      <div>
        <button
          type="submit"
          className={tw.buttonPrimary}
          disabled={!isSubmitEnabled}
        >
          {isLoading ? <LoadingSpinner /> : <span>{submitButtonText}</span>}
        </button>
      </div>
    );
  };

  return (
    <div className={tw.contentWrapper}>
      <div className={tw.section}>
        <BrandingHeader productUrl={redirectUrlParam} />
      </div>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          providerId ? signIn() : getProviderId();
        }}
      >
        <div className={tw.spacedSection}>
          {renderEmailInput()}
          {email && providerId === "password" && renderPasswordInput()}
          {error && (
            <div
              className={`${tw.errorText} !mt-0`}
              data-testid={"error-message"}
            >
              {ERROR_TYPES[error] || ERROR_TYPES["unknown"]}
            </div>
          )}
          {renderSubmitButton()}
          {email && providerId === "password" && (
            <div className="text-center text-sm">
              <Link to="/forgotPassword" state={{ email }} className={tw.link}>
                Forgot password?
              </Link>
            </div>
          )}
        </div>
      </form>
    </div>
  );
}
